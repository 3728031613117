.outer-box {
  display: flex;
  width: 100%; /* Width of the outer box */
  justify-content: center; /* Center items vertically */
  height: 700px; /* Height of the outer box */
  background-color: #1c2e4732; /* Background color of the outer box */
  border: 1px solid #1c2e472e; /* Border of the outer box */
  padding: 20px; /* Padding inside the outer box */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.inner-box {
  display: flex;
  justify-content: center; /* Center items vertically */
  align-items: center;
  flex-direction: column; /* Arrange items in a column */
  width: 99.9%; /* Width of the inner box */
  height: 250px; /* Height of the inner box */
  background-color: #2b2b2b1f; /* Background color of the inner box */
  padding: 15px; /* Padding inside the inner box */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* SVG icon styles */
.img-icon {
  width: 50px; /* Width of the SVG icon */
  height: 50px; /* Height of the SVG icon */
  align-items: center;
}

/* Text styles */
.text {
  display: flex;
  font-size: 24px; /* Font size of the text */
  color: #f3801d; /* Color of the text */
  text-align: center; /* Center the text */
}

.description {
  font-size: 14px; /* Font size of the text */
  color: #f3801d; /* Color of the text */
  text-align: center; /* Center the text */
}
