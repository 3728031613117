.parent-container {
  width: 100%;
  position: relative;
  overflow: scroll;
}

.card-container {
  overflow: auto;
}
.card-style {
  width: 248px;
  height: 248px;
  margin-left: 24px;
  margin-top: 16px;
  float: left;
  background-color: rgb(0, 255, 255);
}

.card-content {
  text-align: left;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.img-add {
  text-align: center;
}

.muiDialog {
  position: absolute;
  display: flex;
  justify-content: end;
  height: 100%;
  left: 78%;
}

.createProjectModal {
  display: grid;
  align-items: flex-start;
  background-color: #0e1c2f;
  color: #fff;
  padding: 32px;
  overflow: scroll;
}

.dialog-field {
  width: 100%;
}

input {
  border-radius: 6px;
  height: 48px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 8px;
  color: orange !important;
  border: transparent;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.08);
  &::placeholder {
    color: #9198a3;
  }
}

label {
  width: 100%;
  height: 24px;
  text-align: start;
}
span {
  text-align: center;
}
.input_description {
  height: 128px;
}

h1 {
  text-align: start;
  color: #fff;
}

.label_name,
.label_project-number,
.label_description,
.label_street,
.label_number,
.label_zipcode,
.label_city {
  display: flex;
  text-align: start;
  color: #fff;
}

.number-and-zipcode {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 1rem;
  height: 6rem;
  margin-bottom: 2rem;
}

.input_zipcode,
.input_number {
  width: 100%;
  display: grid;
}

.label_zipcode {
  margin-left: 20px;
}

.input_zipcode {
  margin-left: 20px;
}
.actions {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  align-items: end;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 32px;
}

.button_save {
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  border: transparent;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(13, 13, 18, 0.06);
  &:hover {
    cursor: pointer;
    outline: orange 1px solid;
  }
}
.button_save:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.button_connect-cloud {
  width: 100%;
  height: 40px;
  background-color: #fff;
  color: #071222;
  border: transparent;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(13, 13, 18, 0.06);
  &:hover {
    cursor: pointer;
    color: orange;
  }
}

.modal-title {
  width: 100%;
}
.close-modal {
  float: right;
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: #9198a3 0.1;
  padding: 0px 8px;
  &:hover {
    color: orange;
    cursor: pointer;
    opacity: 0.9;
    background-color: #9198a3;
    border-radius: 50%;
  }
}

.blur-parent {
  box-shadow: inset 0 0 2000px rgba(0, 0, 0, 0.5);
}
