#mum-qg-dialog-mask {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: none;

  &:has(> .dialog-window[aria-hidden="false"]) {
    display: flex;
  }

  .dialog-window {
    background-color: white;
    color: grey;
    border-radius: var(--radius-medium);
    padding: 1.5rem;
    border: 1px solid grey;
    width: 50vw;
    display: none;

    &[aria-hidden="false"] {
      display: block;
    }

    .dialog-header {
      height: 2.5rem;
      width: 100%;

      .dialog-close {
        float: right;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: 0;
        color: var(--accent-front-color);

        svg {
          fill: grey;
        }

        &:hover {
          opacity: 0.6;
        }
      }

      .dialog-heading {
        float: left;
        margin: 0;
        line-height: 2.5rem;
      }
    }

    .dialog-content {
      width: 100%;

      a {
        color: var(--accent-front-color);
        text-decoration: underline;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }

      .file-upload {
        border-color: var(--accent-front-color) !important;
        color: var(--accent-front-color) !important;
        margin: 1.5rem;

        & > div {
          & > span {
            color: var(--accent-front-color) !important;
          }
        }

        & > svg {
          & > path {
            fill: var(--accent-front-color) !important;
          }
        }
      }
    }

    .dialog-footer {
      width: 100%;
      display: flex;
      justify-content: right;

      .action-button {
        margin-left: 0.75rem;
        background-color: grey;
        color: white;
        border-radius: var(--radius-medium);
        border: 0;
        padding: 0.5rem 1.5rem;

        &:hover {
          /* opacity: 0.6; */
          background-color: var(--accent-back-color);
          color: var(--accent-front-color);
        }
      }

      .dialog-ok {
      }

      .dialog-cancel {
      }
    }
  }
}
