.custom-node {
  border: solid 2px var(--front-color);
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.custom-node-color {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.diagram-container {
  text-align: left;
  font-size: 0.8rem;
  height: 100%;
  display: flex;

  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
  background-size: var(--grid-size) var(--grid-size);
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      var(--back-color) 25%,
      var(--back-color) 26%,
      transparent 27%,
      transparent 74%,
      var(--back-color) 75%,
      var(--back-color) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      var(--back-color) 25%,
      var(--back-color) 26%,
      transparent 27%,
      transparent 74%,
      var(--back-color) 75%,
      var(--back-color) 76%,
      transparent 77%,
      transparent
    );
}
