.sign-out {
  margin-bottom: 10px;
  float: right;
}

.logout-button {
  margin: 24px;
}

.bim-section {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bim-section-skeleton {
  display: grid;
  place-content: center;
}
.button-section-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 400px;
  border-radius: 10px;
  transition: background-color 0.25s ease-in-out;
  box-shadow: 0 0px 6px 0px #43434342;
}

.bim-button-title {
  padding-left: 0.5rem;
}

.button_connect-bim {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  box-shadow: 0px 1px 2px 0px rgba(13, 13, 18, 0.06);
  padding: 8px 12px;
  border-radius: 10px;
  background-color: #fff;
  border: transparent;
  transition: background-color 1s ease-in-out;
  &:hover {
    color: orange;
    cursor: pointer;
    opacity: 0.95;
    outline: 1px orange solid;
  }
}
.hub-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-hub-project {
  width: 500px;
  background-color: var(--content-back-color);
  color: var(--content-front-color);
  box-shadow: 0 0px 6px 0px #43434342;
  border-radius: 10px;
  padding: 12px;
  margin-top: 24px !important;
  transition: background-color 0.25s ease-in-out;
  .choose-hub-project:hover {
    background-color: var(--back-color);
  }
}

.projects-dropdown {
  margin-top: 10px;
}
