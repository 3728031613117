.file-upload {
  border-color: var(--front-color) !important;
  color: var(--front-color) !important;
  margin: 1.5rem;

  & > svg {
    & > path {
      fill: var(--front-color) !important;
    }
  }

  & > div {
    & > span {
      color: var(--front-color) !important;
    }
  }
}
tr th {
  color: rgb(251, 252, 252);
  opacity: 80%;
}
#container {
  padding-left: 2.5rem;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
}

#description {
  text-align: left;
}

table#validation-result-history {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  tr {
    &[data-highlight="true"] {
      background: linear-gradient(
        135deg,
        var(--highlight-back-color-lighter-shade) 0%,
        var(--highlight-back-color) 50%,
        var(--highlight-back-color-darker-shade) 100%
      );
      color: #fff;
      opacity: 0.7;
    }

    th {
      text-align: left;
      border-bottom: 2px solid var(--front-color);
      padding: 1.5em 1.5em 1.5em 0.5rem;
    }

    td {
      padding: 1rem;
      border-bottom: 1px solid var(--front-color);
      text-align: left;
      height: 3.5rem;
    }
  }
}

.history-content {
  margin-top: 3.5rem;
  color: #fff;

  .history-entry-name-label {
    margin-left: 0.75rem;
  }

  .validation-set-name {
    font-weight: bold;
    font-family: monospace;
    margin-left: 0.5rem;
  }
}

.history-entry-actions {
  text-align: center;

  button {
    background-color: transparent;
    color: var(--front-color);
    border-radius: var(--radius-medium);
    border: 0;
    padding: 0.5rem;

    &:hover {
      color: var(--accent-front-color);
      background-color: var(--accent-back-color);
      animation: fade-in 1.5s;
    }
  }
}

#detailed-log-content {
  margin-bottom: 1.5rem;
  max-height: 50vh;
  overflow: auto;
  padding: 0.5rem;

  .validation-result-item {
    width: 100%;
    margin-bottom: 1.5rem;

    & > div {
      & > div {
        display: table-cell;
      }
    }

    .meta-data {
      background: linear-gradient(
        90deg,
        var(--back-color-lighter-shade) 0%,
        var(--back-color) 50%,
        var(--back-color-darker-shade) 100%
      );
      color: var(--front-color);
      border-radius: var(--radius-medium);
      padding: 0.1rem 0;
      display: flex;

      & > div {
        padding-left: 2rem;
      }

      .is-successful {
        &[data-successful="true"] {
          color: green;
        }

        &[data-successful="false"] {
          color: red;
        }
      }

      .name {
        font-weight: bold;
      }

      .actions {
        width: 100%;
        padding-right: 1.5rem;

        .toggle-details {
          float: right;
          background: transparent;
          color: var(--front-color);
          border: 0;
          border-radius: var(--radius-medium);

          &:hover {
            background-color: var(--accent-back-color);
            color: var(--accent-front-color);
          }
        }
      }
    }

    .validation-data {
      .result-details {
        font-family: monospace;
      }

      &[aria-expanded="false"] {
        .result-details {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &[aria-expanded="true"] {
      }
    }
  }
}
