.file-upload {
  border-color: var(--front-color) !important;
  color: var(--front-color) !important;
  margin: 1.5rem;
  background-color: #fff;
  opacity: 40%;

  & > svg {
    & > path {
      fill: var(--front-color) !important;
    }
  }

  & > div {
    & > span {
      color: var(--front-color) !important;
    }
  }
}

#container {
  padding-left: 2.5rem;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
}

#description {
  text-align: left;
}

#file-upload-container {
  display: flex;
  justify-content: center;

  .Upload {
    background-color: #fff;
    opacity: 40%;
  }
}

table#validation-arrangement-models {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  tr {
    &.drag-over {
      background-color: var(--front-color);
      opacity: 0.5;
    }

    th {
      text-align: left;
      border-bottom: 2px solid var(--front-color);
      padding: 1.5em 1.5em 1.5em 0.5rem;
    }

    td {
      padding: 1rem;
      border-bottom: 1px solid var(--front-color);
      text-align: left;
      height: 3.5rem;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.content {
  .drop-component {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .model-name-label {
    margin-left: 0.75rem;
  }

  .validation-status-label {
    margin-left: 0.75rem;
  }

  .validation-status {
    animation: rotation 2s infinite linear;
  }

  .model-help {
    text-align: center;

    button {
      background-color: transparent;
      color: var(--front-color);
      border-radius: var(--radius-medium);
      border: 0;
      padding: 0.5rem;
    }
  }
}

.validation-set-name {
  font-weight: bold;
  font-family: monospace;

  .history-content & {
    margin-left: 0.5rem;
  }
}

.history-content {
  margin-top: 3.5rem;

  .history-entry-name-label {
    margin-left: 0.75rem;
  }
}

.history-entry-actions {
  text-align: center;

  button {
    background-color: transparent;
    color: var(--front-color);
    border-radius: var(--radius-medium);
    border: 0;
    padding: 0.5rem;

    &:hover {
      color: var(--accent-front-color);
      background-color: var(--accent-back-color);
      animation: fade-in 1.5s;
    }
  }
}

#dialog-validation-set-list {
  div.form-group {
    .group {
      display: flex;
    }

    label {
      align-content: stretch;
      width: 100%;
    }

    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      border-radius: 50%;
      background: #e8e8e8;
      border: 3px solid #fff;
      outline: 1px solid #999;
      height: 1.4rem;
      width: 1.5rem;

      &:checked {
        border: 3px solid #25b8ce;
      }
    }
  }
}

.form-group > .form-group.form-children {
  padding-left: 2rem;
  display: flex;
}
