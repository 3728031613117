.placeholder-mask {
  width: 100%;
  height: 100%;
  z-index: 97;
  backdrop-filter: blur(7px);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;

  .placeholder {
    border-width: 0.25rem;
    border-style: solid;
    border-color: var(--accent-front-color);
    background-color: var(--accent-back-color);
    color: var(--accent-front-color);
    padding: 1.5rem;
    filter: opacity(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20rem;
    border-radius: var(--radius-medium);

    .placeholder-content {
      margin-bottom: 1.2rem;
    }

    .placeholder-direction {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

#visual-designer-toolbar {
  position: absolute;
  bottom: 2rem;
  left: 27rem;
  z-index: 98;

  button {
    background-color: var(--accent-back-color);
    color: var(--accent-front-color);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--radius-medium);

    &:hover {
      background-color: var(--back-color);
      color: var(--front-color);
    }

    &.active {
      background-color: var(--back-color);
      color: var(--front-color);

      &:hover {
        background-color: var(--accent-back-color);
        color: var(--accent-front-color);
      }
    }
  }
}
