.form-group {
  display: flex;
  margin-bottom: 0.5rem;

  label {
    width: 50%;
    max-width: 10rem;
    display: inline-block;
  }
}
