.post {
  padding-left: 2.5rem;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
}

#description {
  text-align: left;
}

.content {
  margin-top: 2.5rem;
  width: 100%;
  color: #ffffff;

  .action-bar {
    float: right;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    tr {
      th {
        text-align: left;
        border-bottom: 1px solid #fff;
        padding: 1.5em 1.5em 1.5em 0.5rem;
      }

      td {
        height: 8rem;
        padding: 1rem;
        border-bottom: 1px solid var(--front-color);
        text-align: left;
      }
    }
  }

  .user-account {
    display: flex;

    .user-image {
      display: flex;
      padding-right: 1.5rem;

      img {
        border-radius: var(--radius-full);
        width: 2.5rem;
        border: 1px solid var(--accent-front-color);
        margin: auto;
        margin-right: 0;
        display: inline-block;
      }
    }

    .user-information {
      line-height: 1.75rem;
      margin-top: 1.5rem;

      .user-itsyou {
        border-radius: var(--radius-medium);
        padding: var(--radius-small);
        background-color: #80ff80;
        margin-left: 0.25rem;
        clear: right;
        color: #333;
      }

      .user-username {
        display: block;
      }
    }
  }

  .user-maxrole span {
    border-radius: var(--radius-medium);
    padding: 0.25rem;
    background-color: #ddd;
    color: #333;
  }
}
