#project-properties {
  width: fit-content;
  .property {
    display: table;

    text-align: left;
    display: table-row;

    .label,
    .value {
      display: table-cell;
      color: #ffffff;
    }

    .label {
      padding-right: 3.5rem;
      vertical-align: bottom;
    }
  }

  .long-property {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;

    #projectDescription {
      color: #ffffff;
    }
  }
}

#description {
  margin-bottom: 0.8rem;
}

.button_save-form {
  background-color: #f38237;
  margin-top: 1rem;
  color: white;
  border: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
    outline: 1px solid orange;
  }
}

.button_dissabled {
  opacity: 0.3;
  background-color: #f38237;
  margin-top: 1rem;
  color: white;
  border: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
}

.sign-in {
  background-color: #ffffff;
  color: #000;
  box-shadow: 0 0px 6px 0px #43434342;
  border-radius: 10px;
  padding: 24px;
  margin-top: 24px !important;
  margin: 200px;

  .sign-in-title {
    align-items: center;
    display: inline-flex;

    gap: 16px;
  }
}
