.base-menu-toggle {
  height: 2.5rem;
  width: 7.5rem;
  margin-left: 0.5rem;
  background-color: transparent;
  color: var(--accent-front-color);
  border: 0px;
  border-radius: var(--radius-medium);
  float: left;
  text-transform: uppercase;

  &:hover,
  &[aria-expanded="true"] {
    color: var(--front-color) !important;
    background-color: var(--back-color) !important;
  }
}

.base-menu-content {
  position: absolute;
  display: none;
  background-color: var(--back-color);
  color: var(--front-color);
  border: 1px solid var(--front-color);
  z-index: 99;
  text-transform: uppercase;
  border-radius: var(--radius-medium);
  margin: -1px 1px 1px -1px;

  &[aria-hidden="false"] {
    display: block;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0.75rem;
      margin: 0 -1px -1px 0px;

      &:first-child {
        border-radius: var(--radius-small) var(--radius-small) 0 0;
      }

      &:last-child {
        border-radius: 0 0 var(--radius-small) var(--radius-small);
      }

      &:not(:first-child) {
        border-top: 1px solid var(--front-color);
      }

      &:hover {
        background-color: var(--accent-back-color);
        color: var(--accent-front-color);
      }
    }
  }
}
