@import "./config/variables.module.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:has(> #root:empty) {
    background: url(logo.min.svg) no-repeat #fff;
    background-size: 25em;
    background-position: 20% 20%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-front-color: 0, 0%;
  --primary-front-color-lightness: 20%;
  --front-color: hsl(
    var(--primary-front-color),
    var(--primary-front-color-lightness)
  );
  --front-color-darker-shade: hsl(
    var(--primary-front-color),
    calc(var(--primary-front-color-lightness) - 30%)
  );
  --front-color-lighter-shade: hsl(
    var(--primary-front-color),
    calc(var(--primary-front-color-lightness) + 30%)
  );
  --primary-back-color: 180, 14%;
  --primary-back-color-lightness: 93%;
  --back-color: hsl(
    var(--primary-back-color),
    var(--primary-back-color-lightness)
  );
  --back-color-darker-shade: hsl(
    var(--primary-back-color),
    calc(var(--primary-back-color-lightness) - 30%)
  );
  --back-color-lighter-shade: hsl(
    var(--primary-back-color),
    calc(var(--primary-back-color-lightness) + 30%)
  );
  --content-back-color: #fff;
  --content-front-color: #2c3e50;
  --secondary-front-color: 0, 0%;
  --secondary-front-color-lightness: 80%;
  --accent-front-color: hsl(
    var(--secondary-front-color),
    var(--secondary-front-color-lightness)
  );
  --accent-front-color-darker-shade: hsl(
    var(--secondary-front-color),
    calc(var(--secondary-front-color-lightness) - 30%)
  );
  --accent-front-color-lighter-shade: hsl(
    var(--secondary-front-color),
    calc(var(--secondary-front-color-lightness) + 30%)
  );
  --secondary-back-color: 0, 0%;
  --secondary-back-color-lightness: 20%;
  --accent-back-color: hsl(
    var(--secondary-back-color),
    var(--secondary-back-color-lightness)
  );
  --accent-back-color-darker-shade: hsl(
    var(--secondary-back-color),
    calc(var(--secondary-back-color-lightness) - 30%)
  );
  --accent-back-color-lighter-shade: hsl(
    var(--secondary-back-color),
    calc(var(--secondary-back-color-lightness) + 30%)
  );
  --tertiary-front-color: 0, 0%;
  --tertiary-front-color-lightness: 91%;
  --highlight-front-color: hsl(
    var(--tertiary-front-color),
    var(--tertiary-front-color-lightness)
  );
  --highlight-front-color-darker-shade: hsl(
    var(--tertiary-front-color),
    calc(var(--tertiary-front-color-lightness) - 30%)
  );
  --highlight-front-color-lighter-shade: hsl(
    var(--tertiary-front-color),
    calc(var(--tertiary-front-color-lightness) + 30%)
  );
  --tertiary-back-color: 35, 100%;
  --tertiary-back-color-lightness: 46%;
  --highlight-back-color: hsl(
    var(--tertiary-back-color),
    var(--tertiary-back-color-lightness)
  );
  --highlight-back-color-darker-shade: hsl(
    var(--tertiary-back-color),
    calc(var(--tertiary-back-color-lightness) - 10%)
  );
  --highlight-back-color-lighter-shade: hsl(
    var(--tertiary-back-color),
    calc(var(--tertiary-back-color-lightness) + 10%)
  );
  --radius-full: 50%;
  --radius-medium: 0.5rem;
  --radius-small: 0.25rem;
  --animation-duration: 1.5s;
  --grid-size: 0.7rem;
}
