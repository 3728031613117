.content-container {
  padding-left: 2.5rem;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
}

.notification-key {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: var(--accent-back-color);
  color: var(--accent-front-color);
  border-radius: var(--radius-medium);
  margin-right: 0.5rem;
}
#description {
  color: aliceblue;
}
