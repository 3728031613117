.Hero {
  height: 100vh;
  background-color: #071222;
  background-image: url(../src/components/Images/background-image.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-through {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

html {
  margin: 0px;
  height: 100%;
}

body {
  margin: 0px;
  height: 100%;
}

button {
  font-size: 1rem;
}

#title {
  text-align: left;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  background-color: #071222;

  &.edgy {
    --radius-full: 0;
    --radius-medium: 0;
    --radius-small: 0;
  }

  &.no-animation {
    --animation-duration: 0s;
  }

  #content-pane {
    display: flex;
    background-color: #071222;
  }

  #main-content {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .spinner {
    width: 120px !important;
    height: 120px !important;
    position: absolute;
    top: 35%;
    left: 50%;
  }

  .action-button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--radius-small);
    color: var(--front-color);
    background-color: var(--back-color);
    border: 1px solid var(--front-color);

    &:hover {
      color: var(--accent-front-color);
      background-color: var(--accent-back-color);
      border: 1px solid var(--accent-front-color);
    }
  }
}

#root {
  height: 100%;

  &:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid var(--back-color);
    border-left: 1.1em solid var(--front-color);
    border-right: 1.1em solid var(--front-color);
    border-radius: 50%;
    animation: loading-animation 1.1s infinite linear;
  }

  &:has(> [data-amplify-authenticator]) {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background-color: var(--back-color);
    color: var(--front-color);
    --amplify-components-authenticator-router-background-color: var(
      --accent-back-color
    );
    --amplify-components-authenticator-state-inactive-background-color: var(
      --accent-back-color
    );
    --amplify-components-text-color: var(--accent-front-color);
    --amplify-components-tabs-item-color: var(--accent-front-color);
    --amplify-components-tabs-item-active-color: var(--highlight-back-color);
    --amplify-components-tabs-item-focus-color: var(--highlight-back-color);
    --amplify-components-tabs-item-hover-color: var(--accent-front-color);
    --amplify-components-tabs-item-active-border-color: var(
      --highlight-back-color
    );
    --amplify-components-field-label-color: var(--accent-front-color);
    --amplify-components-heading-color: var(--accent-front-color);
    --amplify-components-button-primary-background-color: var(
      --highlight-back-color
    );
    --amplify-components-button-primary-hover-background-color: var(
      --highlight-back-color-lighter-shade
    );
    --amplify-components-button-primary-focus-background-color: var(
      --highlight-back-color-darker-shade
    );
    --amplify-components-button-primary-active-background-color: var(
      --highlight-back-color-darker-shade
    );
    --amplify-components-button-link-hover-background-color: var(
      --highlight-back-color-lighter-shade
    );
    --amplify-components-button-link-focus-background-color: var(
      --highlight-back-color-darker-shade
    );
    --amplify-components-button-link-focus-color: #fff;
    --amplify-components-button-link-active-background-color: var(
      --highlight-back-color-darker-shade
    );
    --amplify-components-button-link-active-color: #fff;
    --amplify-components-button-link-hover-color: var(
      --highlight-front-color-lighter-shade
    );
    --amplify-components-button-link-color: var(--accent-front-color);
    --amplify-components-button-link-focus-box-shadow: 0px 0px 0px 1px #fff;
    --amplify-components-button-color: var(--accent-front-color);
    --amplify-components-button-hover-color: var(--front-color);
    --amplify-components-button-hover-background-color: var(--back-color);
    --amplify-components-button-focus-color: var(--front-color-darker-shade);
    --amplify-components-button-focus-background-color: var(
      --back-color-darker-shade
    );
    --amplify-components-button-active-color: var(--front-color-lighter-shade);
    --amplify-components-button-active-background-color: var(
      --back-color-lighter-shade
    );

    input {
      color: var(--content-front-color);
      background-color: var(content-back-color);
    }

    .amplify-field-group--horizontal {
      .amplify-button {
        background-color: var(content-back-color);
      }
    }
  }
}

.chevron {
  border-style: solid;
  border-color: var(--front-color);
  border-width: 0 0.5rem 0.5rem 0;
  display: inline-block;
  padding: 0.5rem;

  &.accent {
    border-color: var(--accent-front-color);
  }

  &.highlight {
    border-color: var(--highlight-front-color);
  }

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.left-down {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

:root {
  .alternate {
    * {
      &[data-skeleton="true"] {
        background-color: var(--accent-front-color);
        color: var(--accent-front-color);
      }
    }
  }

  * {
    &[data-skeleton="true"] {
      border-radius: var(--radius-medium);
      background-color: var(--accent-back-color);
      color: var(--accent-back-color);
      animation-name: fade-through;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      min-height: 2rem;

      th {
        border-bottom: 0 !important;
      }
    }

    &[data-loading="true"] {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      border-radius: 50%;
      border-left: #fff 2px solid;
      border-right: #fff 2px solid;
      animation: rotation 2s infinite linear;
    }

    label,
    input,
    fieldset {
      border-color: #ebf0f0;
      color: #ffffff;

      :hover {
        color: #fff;
        border-color: #fff !important;
      }
    }
  }
}

.dialog-window {
  .form-group {
    display: flex;
    margin-bottom: 0.5rem;

    label {
      width: 50%;
      max-width: 10rem;
      display: inline-block;
    }

    input,
    select {
      height: 2rem;
    }

    input,
    select,
    .rw-widget {
      width: 100%;
      max-width: 20rem;
    }

    input[type="checkbox"] {
      margin-left: calc(min(50%, 10rem) - 0.25rem);
      width: 2rem;
      transform: scale(0.75);

      & + label {
        display: flex;
        align-items: center;
        // width: calc(100% - (min(50%, 10rem) - 2rem));
        max-width: fit-content;
      }
    }

    &:first-child {
      margin-top: 0.25rem;
    }
  }
}

.app-environment {
  background-color: #ff0000;
  border-radius: var(--radius-medium);
  padding: 0.5rem;
  color: #fff;
  font-weight: bold;
}
